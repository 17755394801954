.create_hcp_screen .btn_org {
    background-color: #3f2783 !important;
    color: #fff !important;
}

.create_hcp_screen .border_line {
    /* border: 1px solid #3f2783; */
    margin-top: 40px;
    margin-bottom: 40px;
}

.create_hcp_screen .border_line {
    text-align: center;
    display: block;
}

.create_hcp_screen .border_line:after {
    content: '';
    border: 1px solid #3f2783;
    /* margin-top: 40px; */
    width: 100%;
    display: flex;
    margin-bottom: 40px;
}

.create_hcp_screen .browser_file {
    padding: 8px 15px;
    border-radius: 50px;
    color: #ffffff;
    border: 2px solid #f8f9fa;
    width: 153px;
    /* margin-top: 24px; */
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    margin: auto;
    margin-bottom: 10px;
}

.create_hcp_screen .patient_box {
    max-height: 210px;
    /* overflow: auto; */
}

.create_hcp_screen .cynsqJ {
    display: none !important;
}