*{font-family: 'Poppins';

}

.drop_zone {
    max-width: 100%;
    height: 300px;
    /* padding: 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
    border: 2px dashed rgba(63, 39, 131, 0.2);
    border-radius: 10px;
	margin-bottom: 20px;
}
  
  .drop_zone--over {
	border-style: solid;
  }
  
  .drop_zone__input {
	display: none;
  }
  
  .drop_zone__thumb {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	background-color: #cccccc;
	background-size: cover;
	position: relative;
  }
  
  .drop_zone__thumb::after {
	content: attr(data-label);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 5px 0;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	text-align: center;
  }
  .drop_zone h4 {
    color: #000;
    font-size: 24px;
}
.drop_zone p {
    color: #000;
    font-size: 12px;
    margin-bottom: 0;
}
.drop_zone input {
    background-color: #ffffff !important;
    border: 3px solid #3F2783;
    max-width: 125px;
    border-radius: 20px;
    margin: 10px auto;
    outline: none;
}
.browserfilebtn {
    padding: 8px 15px;
    border-radius: 50px;
    color: #3f2783;
    border: 2px solid #3f2783;
    width: 141px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

