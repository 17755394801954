.groups__list table.custom-tbl tr .col-action {
    min-width: 80px;
}
.groups__list table.custom-tbl thead tr .col-sno {
    width: 50px;
}
.groups__list table.custom-tbl thead tr th {
    width: 30%;
}
.create_group_screen .multi_select_main {
    max-width: 285px;
}
.create_group_screen .multi_select_main .searchWrapper, .create_group_screen .multi_select_main .dropdown-container {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
}
.create_group_screen .button_box .next_btn {
    font-size: 18px;
}
.create_group_screen .rmsc.multi_select_drp {
    max-width: 285px;
}
.create_group_screen .rmsc.multi_select_drp .dropdown-container{
    border: 2px solid #3f2783;
    border-radius: 10px;
}
.groups__list table .col-sno {
    width: 8%;
}
.groups__list table .col-id {
    width: 8% !important;
}
.groups__list table .col-status {
    width: 8% !important;
}
.org_sharing_width thead tr th {
    width: 15% !important;
}