/* .selected_plans_table table {
    border-collapse: collapse;
    width: 100%;
}
.selected_plans_table th, .selected_plans_table td {
    border: 1px solid #3f2783;
    padding: 8px;
    text-align: left;
}
.selected_plans_table th {
    background-color: #f2f2f2;
    color: #3f2783;
} */

.selected_plans_table table {
  max-width: 960px;
  margin: 10px 0;
  width: 100%;
}

.selected_plans_table thead th {
  font-weight: 600;
  background: #ECE9F3;
  color: #3F2783;
}
.selected_plans_table table>:not(:first-child){
  border-top: none;
}

.selected_plans_table tr {
  background: #f4f7f8;
  border-bottom: 1px solid #FFF;
  margin-bottom: 5px;
}

.selected_plans_table tr:nth-child(even) {
  background: #ECE9F3;
}

.selected_plans_table th, .selected_plans_table td {
  text-align: left;
  padding: 20px 8px;
  font-weight: 400;
}

.selected_plans_table tbody tr {
  background: none;
}

.selected_plans_table tbody td {
    padding: 10px 2px;
    padding-left: 8px;
    font-size: 14px;
    font-style: italic;
    color: #3f2783;
}

.edit_org_screen .edit_plan_listtable{
    padding-top: 10px;
    /* padding-bottom: 40px; */
}
.edit_org_screen .edit_plan_listtable .plan_btn_main{
    position: relative;
}
.edit_org_screen .edit_plan_listtable .plan_btn_main button{
    position: absolute;
    right: 0;
    top: -8px;
}
.admin_edit__org .custom_check_box [type="checkbox"]+label:before {
    border: 2px solid rgb(63 39 131 / 39%);
    border-radius: 100%;
    background: #e9ecef;
}
.admin_edit__org .custom_check_box.tick [type="checkbox"]:checked+label:after {
  background-color: #3f278373;
}