.profile__left__sec {
    display: flex;
    padding-left: 0;
}
.profile_img {
    margin-left: 0;
    margin-right: 20px;
}  
.profile_img label.user_letter {
    line-height: 72px;
    width: 76px;
    height: 76px;
    font-size: 46px;
}
p.red_txt {
    color: #ff0000;
    margin-bottom: 0;
}
.profile_name {
    text-align: center;
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    text-align: left;
    padding-top: 2px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.profile_age {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    display: block;
    color: #000;
}
.detail__txt {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    margin-bottom: 0;
}
.detail__txt1  {
   padding-bottom: 6px;
}
.detail__txt1 strong {
    min-width: 130px;
    display: inline-block;
    margin-bottom: 0;
}
.detail__txt strong {
    min-width: 64px;
    display: inline-block;
    margin-bottom: 0;
}

.patient_demographic input {
    width: 100%;
    margin: 5px 0;
    padding: 12px 15px;
    border-radius: 25px;
    border: 1px solid #7867a8;
    outline: none;
    -webkit-appearance: none;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
}
.all__grid_detail {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-direction: row;
    row-gap: 15px;
    column-gap: 15px;
}
.grid_item_30 {
    width: 29%;
}
.grid_item_40 {
    width: 39%;
}
.grid__item .card__head {
    padding: 15px 0;
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    margin-left: 0;
    margin-right: 0;
}
.grid__item .card__content {
    padding: 20px 25px;
}
.grid__item .graphic__title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}
.grid__item {
    background: #FFFFFF;
    border-radius: 20px;
    min-height: 100px;
}
.grid__item .detail__txt strong {
    min-width: 100px;
    max-width: 100px;
    display: inline-block;
}
.detail__txt input[disabled] {
    padding-bottom: 0;
}
@media only screen and (max-width: 992px) {
    .grid_item_30 {
        width: 49%;
    }
    .grid_item_40 {
        width: 49%;
    }
}
@media only screen and (max-width: 1280px) {
    .all__grid_detail {
        column-gap: 14px;
    }
}
