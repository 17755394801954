/* .not__found {
    background-color: #fff;
    padding: 50px 30px;
    min-height: 400px;
}
.not__found .notf_img{
   width: 420px;
   display: block;
   margin: 0 auto;
} */

.not__found {
    background-color: #fff;
    padding: 50px 30px;
    min-height: 400px;
}
.not__found .notf_img{
   width: 420px;
   display: block;
   margin: 0 auto;
}
.content {
    margin: 0 auto;
    width: 1236px;
}
.header {
    padding: 22px 0 20px 0;
    border-bottom: 2px solid #3f2783;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.reg__logo {
    display: flex;
    align-items: center;
    width: 172px;
}
.signout-link {
    color: #3F2783;
    font-weight: 600;
}
.akunah_logo_rt_txt {
    font-size: 28px;
    margin-left: 10px;
    color: #3f2783;
}
.body-content.inner-body-content {
    padding: 90px 70px;
}
.error-page {
    text-align: center;
    padding: 150px 0;
}
.error-page h4 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 30px;
}
.footer {
    padding: 22px 0 20px 0;
    border-top: 2px solid #3f2783;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}
a.outline-btn:hover, a.outline-btn:focus, .outline-btn:hover, .outline-btn:focus {
    background: transparent;
    color: #3F2783;
    outline: none;
    text-decoration: none;
}
.error-page .outline-btn {
    height: 50px;
    width: 200px;
    font-size: 18px;
    line-height: 50px;
}

a.outline-btn, .outline-btn {
    width: 264px;
    height: 73px;
    border: 2px solid #3F2783;
    border-radius: 50px;
    font-size: 24px;
    color: #fff;
    display: inline-block;
    line-height: 65px;
    text-align: center;
    background: #3F2783;
    font-family: 'Poppins', sans-serif;
}
