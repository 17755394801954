.approve_modal_main .modal-dialog{
    width: 400px;
}
.approve_modal_main .approve_pp_icon {
    margin-top: 20px;
    width: 24px;
}
.approve_modal_main .center_title {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    font-weight: normal;
    margin-bottom: 0;
}
.approve_modal_main .modal-footer {
    justify-content: center;
}
.approve_modal_main .btn_outline {
    width: 140px;
    margin-right: 15px;
}
.approve_modal_main .btn_danger {
    width: 140px;
}
.approve_modal_main .btn_green {
    width: 140px;
}