.plan__list table.custom-tbl tr .col-action {
    min-width: 80px;
}
.plan__list table.custom-tbl thead tr .col-sno {
    width: 100px;
}
.plan__list table.custom-tbl thead tr .col-sno td {
    width: 100px;
}
.plan__list table.custom-tbl thead tr th {
    width: 13%;
}