.hcp__list table.custom-tbl tr .col-action {
    min-width: 100px;
}
.hcp__list table.custom-tbl thead tr .col-type,  .hcp__list table.custom-tbl tbody tr .col-type{
    width: 12% !important;
}
table.custom-tbl thead tr th {
    width: 95px;
}

.table3 thead tr th {
    width: 17% !important;
}