/* *:focus {
    outline: none !important;
    border: none;
    border-color: #fff !important;
  } */

body {
    font-size: 14px;
}

.css-1qqsdnr-MuiAutocomplete-root {
    max-width: 655px !important;
    width: 100% !important;
}

label.user-letter {
    width: 40px;
    height: 40px;
    margin: 0;
    background: #5e60ce;
    border-radius: 50%;
    line-height: 36px;
    border: 2px solid #3F2783;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}
.user-letter img {
    width: 44px;
    height: 44px;
    margin: -3px;
    background: #5e60ce;
    border-radius: 50%;
    border: 2px solid #3F2783;
}
.user-letter.large img {
    width: 95px !important;
    height: 95px !important;
}

label.user-letter.large {
    width: 93px;
    height: 90px;
    line-height: 81px;
    font-size: 40px;
    border: 1.8px solid #3f2783;
}

label.user-letter.small {
    width: 28px;
    height: 28px;
    line-height: 26px;
    font-size: 18px;
}

.profile_pic_letter {
    font-size: 54px;
    color: #fff;
    background: linear-gradient(0deg, #5e60ce, #5e60ce);
    display: inline;
    border-radius: 50px;
    padding: 0px 25px;
    border: 1.8px solid #3f2783;
    font-weight: 600;
    width: 90px;
    height: 90px;
    line-height: 81px;
}

.form-control {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    color: #3f2783;
    padding: 0px 26px 0px 14px;
    height: 40px;
    background: transparent;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-control:focus {

    border-color: rgba(63, 39, 131, 0.7) !important;

}

.form-select {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    color: #3f2783;
    padding: 0px 26px 0px 14px;
    height: 40px;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
}

.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 16px;
}

label,
.form-label {
    color: #3f2783;
    margin-bottom: 15px;
    font-weight: 600;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(63, 39, 131, 0.5);
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(63, 39, 131, 0.5);
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(63, 39, 131, 0.5);
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(63, 39, 131, 0.5);
}

.search-box .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(63, 39, 131, 0.3);
}

.custom_check_box [type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.custom_check_box [type="checkbox"]+label {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #3f2783;
}

.custom_check_box.large [type="checkbox"]+label {
    padding-left: 40px;
    padding-right: 40px;
    line-height: 25px;
}

.custom_check_box [type="checkbox"]+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 100%;
    background: #fff;
}

.custom_check_box.large [type="checkbox"]+label:before {
    width: 24px;
    height: 24px;
}

.custom_check_box [type="checkbox"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #3f2783;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom_check_box.tick [type="checkbox"]:checked+label:after {
    background: url(../images/check.png) no-repeat;
    background-color: #3f2783;
    background-position: center;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 1px;
}

.custom_check_box.tick.large [type="checkbox"]:checked+label:after {
    width: 22px;
    height: 22px;
}

.custom_radio_box [type="radio"] {
    position: absolute;
    opacity: 0;
}

.custom_radio_box [type="radio"]+label {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #3f2783;
}

.custom_radio_box [type="radio"]+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 100%;
    background: #fff;
}

.custom_radio_box [type="radio"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #3f2783;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom_radio_box.large [type="radio"]+label {
    padding-left: 40px !important;
    font-weight: 400;
}

.custom_radio_box.large [type="radio"]+label:before {
    width: 23px;
    height: 23px;
}

.custom_radio_box.large [type="radio"]:checked+label:after {
    width: 13px;
    height: 13px;
    top: 5.5px;
    left: 4.5px;
}

.custom_radio_box.tick [type="radio"]:checked+label:after {
    background: url(../images/check.png) no-repeat;
    background-color: #3f2783;
    background-position: center;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 1px;
}

.custom_radio_box.tick.large [type="radio"]:checked+label:after {
    width: 22px;
    height: 22px;
}

.settings_main .custom_check_box [type="checkbox"]+label,
.add_org_screen .custom_check_box [type="checkbox"]+label,
.edit_org_screen .custom_check_box [type="checkbox"]+label {
    padding-left: 40px;
    font-weight: 400;
    font-size: 14px;
}

.btn_disabled, .clk_btn_disbled:disabled, .clk_btn_disbled[disabled] {
    width: 140px;
    height: 40px;
    border: 2px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: default !important;
    border-radius: 20px;
    pointer-events: none;
}
.clk_btn_disbled:disabled:hover, .clk_btn_disbled[disabled]:hover, 
.clk_btn_disbled:disabled:focus, .clk_btn_disbled[disabled]:focus {
    border: 2px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    outline: none !important;
    pointer-events: none;
    text-decoration: none;
}

.btn_disabled:hover,
.btn_disabled:focus {
    border: 2px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    outline: none !important;
    pointer-events: none;
    text-decoration: none;
}

.btn_light_disabled,
.btn_light_disabled:focus,
.btn_light_disabled:hover {
    background-color: #d9d4e6 !important;
    border: 2px solid #d9d4e6 !important;
    color: #7968a8 !important;
    pointer-events: none;
}

.btn_fill {
    width: 140px;
    height: 40px;
    color: #fff;
    background: #3f2783;
    border-radius: 20px;
    border: 2px solid #3f2783;
    display: inline-block;
    line-height: 38px;
    padding: 0 22px;
}

.btn_fill:hover,
.btn_fill:focus {
    background: transparent;
    color: #3f2783;
    outline: none;
    text-decoration: none;
    border: 2px solid #3f2783;
}

.btn_danger {
    width: 140px;
    height: 40px;
    background: #ff0000;
    color: #fff;
    border-radius: 20px;
    border: 2px solid #ff0000;
    display: inline-block;
}

.btn_danger:hover,
.btn_danger:focus {
    background: transparent;
    color: #ff0000;
    outline: none;
    text-decoration: none;
    border: 2px solid #ff0000;
}

.btn_green {
    width: 140px;
    height: 40px;
    background: #6fcf97;
    color: #fff;
    border-radius: 20px;
    border: 2px solid #6fcf97;
    display: inline-block;
}

.btn_green:hover,
.btn_green:focus {
    background: transparent;
    color: #6fcf97;
    outline: none;
    text-decoration: none;
    border: 2px solid #6fcf97;
}

.btn_green_outline {
    width: 140px;
    height: 40px;
    background: transparent;
    color: #6fcf97;
    border-radius: 20px;
    border: 2px solid #6fcf97;
    display: inline-block;
}

.btn_green_outline:hover,
.btn_green_outline:focus {
    background: #6fcf97;
    color: #fff;
    outline: none;
    text-decoration: none;
    border: 2px solid #6fcf97;
}

.btn_nill {
    background: transparent;
    border: none;
    color: #3f2783;
}

.btn_nill:hover,
.btn_nill:focus {
    background: transparent;
    border: none;
    color: #3f2783;
}

button.btn {
    font-size: 14px;
}

.btn_outline {
    border: 2px solid #3f2783;
    color: #3f2783;
    border-radius: 50px;
    font-size: 14px;
    padding: 0 22px;
    height: 40px;
    line-height: 38px;
    background: transparent;
}

.btn_outline:hover {
    background: #3f2783 !important;
    color: #fff !important;
}

a,
a:hover {
    color: #3f2783;
    text-decoration: none;
}

.form-control::-webkit-input-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-control:-moz-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-control::-moz-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-control:-ms-input-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-select {
    font-size: 14px;
}

.multi_select_drp {
    max-width: 260px;
}

.multi_select_drp.br_10_multi .css-yk16xz-control,
.multi_select_drp.br_10_multi .css-1pahdxg-control,
.multi_select_drp.br_10_multi .css-1s2u09g-control {
    border-radius: 10px;
}

.multi_select_drp .css-yk16xz-control,
.multi_select_drp .css-1pahdxg-control,
.multi_select_drp .css-1s2u09g-control,
.time_picker .react-time-picker__wrapper {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    color: #3f2783;
    padding: 0;
    min-height: 40px;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
}

.multi_select_drp .css-1okebmr-indicatorSeparator,
.multi_select_drp .css-tlfecz-indicatorContainer,
.multi_select_drp span+.css-1gtu0rj-indicatorContainer {
    display: none;
}


.multi_select_drp_select{
    max-width: 260px !important;
}




.multi_select_drp_select .css-yk16xz-control,
.multi_select_drp_select .css-1pahdxg-control,
.multi_select_drp_select .css-1s2u09g-control,
.multi_select_drp_select .css-13cymwt-control,
.multi_select_drp_select .css-t3ipsp-control,
.time_picker .react-time-picker__wrapper {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 9px !important;
    color: #3f2783 !important;
    padding: 0 !important;
    min-height: 40px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
    box-shadow: none !important;
}
.multi_select_drp_select .css-13cymwt-control{
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 9px !important;
    color: #3f2783 !important;
    padding: 0 !important;
    min-height: 40px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
    box-shadow: none !important;
}
.multi_select_drp_select .css-t3ipsp-control{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}

.multi_select_drp_select .css-t3ipsp-control:hover{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}



.css-t3ipsp-control:hover{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}





.time_picker {
    margin-bottom: 6px;
}

.time_picker label,
.time_picker .form-label {
    color: #3f2783;
    margin-bottom: 15px;
    font-weight: 600;
    display: block;
}

.time_picker .react-time-picker__wrapper {
    width: 260px;
    padding: 0 15px;
}

.time_picker .react-time-picker__button svg {
    stroke: #3f2783;
}

.time_picker .react-time-picker__inputGroup__input {
    color: #3f2783;
}

.mr_35 {
    margin-right: 35px;
}

.mr_30 {
    margin-right: 30px;
}

.mr_20 {
    margin-right: 20px;
}

.mr_15 {
    margin-right: 15px;
}

.mt_15 {
    margin-top: 15px;
}

.mt_20 {
    margin-top: 20px;
}

.mt_30 {
    margin-top: 30px;
}

.mt_64 {
    margin-top: 64px;
}

.mt_4 {
    margin-top: 4px;
}

.pt_10 {
    padding-top: 10px;
}

.pt_15 {
    padding-top: 15px;
}

.pt_20 {
    padding-top: 20px;
}

.pannel_pd.pt_20 {
    padding-top: 20px;
}

.pt_30 {
    padding-top: 30px;
}

.pt_35 {
    padding-top: 35px !important;
}

.pt_50 {
    padding-top: 50px;
}

.pt_70 {
    padding-top: 70px;
}

.pr_0 {
    padding-right: 0;
}
.pr_20 {
    padding-right: 20px;
}

.pl_0 {
    padding-left: 0;
}

.pb_30 {
    padding-bottom: 30px;
}

.pb_20 {
    padding-bottom: 20px;
}

.pb_10 {
    padding-bottom: 10px;
}

.pb_5 {
    padding-bottom: 5px;
}

.pb_50 {
    padding-bottom: 50px;
}

.pb_10 {
    padding-bottom: 10px;
}

.pd_10 {
    padding: 10px;
}

.pd_25 {
    padding: 25px;
}

.bg_white {
    background-color: #fff;
}

h4.ml_0 {
    margin-left: 0px;
}

.ml_15 {
    margin-left: 15px;
}

.ml_4 {
    margin-left: 4px;
}

.mrg_0 {
    margin: 0;
}

h4.ml_0 {
    margin-left: 0px;
}

.adv_search_sec {
    padding: 0 25px;
}

.f_12 {
    font-size: 12px;
}

.f_16 {
    font-size: 16px;
}

.f_40 {
    font-size: 40px !important;
}

.f_24 {
    font-size: 24px !important;
}

.small,
small {
    font-size: smaller;
}

.h_150 {
    height: 150px !important;
}

.h_200 {
    height: 200px !important;
}

.mxw_100,
.mxw_100 .form-select {
    max-width: 100%;
}

.mxw_120 {
    max-width: 120px;
}

.mxw_70,
.mxw_70 .form-select {
    max-width: 70%;
}

.w_100 {
    width: 100%;
}

.wpx_100 {
    width: 100px;
}

.mxw_260 {
    max-width: 260px !important;
}

.mxw_285 .form-select {
    max-width: 100%;
}

.mxw_285 {
    max-width: 285px !important;
}

.mxwp_90 {
    max-width: 90% !important;
}

.mxwp_90_select .form-select {
    max-width: 90% !important;
}

.mxwp_80_select .form-select {
    max-width: 80% !important;
}

.mxwp_80 {
    max-width: 80% !important;
}

.mxh_520 {
    height: auto;
    max-height: 520px;
}

.mx_760 {
    max-width: 760px;
}

.br_10,
.br_10 .form-select {
    border-radius: 10px;
}

.br_15,
.br_15 .form-select {
    border-radius: 15px;
}

.mb_0 {
    margin-bottom: 0;
}

.mb_8 {
    margin-bottom: 8px;
}

.mb_5 {
    margin-bottom: 5px;
}

.mb_20 {
    margin-bottom: 20px;
}

.mb_30 {
    margin-bottom: 30px;
}

.mb_40 {
    margin-bottom: 40px;
}

.mb_50 {
    margin-bottom: 50px;
}

.mh_270 {
    min-height: 270px;
}

.data_export .mh_420 {
    min-height: 420px;
}

.mh_50 {
    min-height: 50px;
}

b,
strong,
label {
    font-weight: 600;
    margin-bottom: 10px;
}

.text-danger {
    color: #ff0000 !important;
}

.whitebox {
    background: #fff;
    border-radius: 20px;
    padding: 25px 20px;
    margin-bottom: 20px;
}

.box-header {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    margin-bottom: 25px;
}

.box-title {
    margin: 0;
    font-weight: 600;
}

.f_600 {
    font-weight: 600;
}

.f_500 {
    font-weight: 500;
}

.f_400 {
    font-weight: 400;
}

.label_400 label {
    font-weight: 400;
}

.custom_check_box.f_400 label {
    font-weight: 400;
}

.f_22 {
    font-size: 22px;
}

.mb_100 {
    margin-bottom: 100px;
}

.mb_10 {
    margin-bottom: 10px;
}

.mb_30 {
    margin-bottom: 30px !important;
}

.mb_15 {
    margin-bottom: 15px;
}

.ml_20 {
    margin-left: 20px;
}

.ml_25 {
    margin-left: 25px !important;
}

.pt_6 {
    padding-top: 6px;
}

.c_pointer {
    cursor: pointer;
}

.label_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
}

.welcome-section {
    text-align: center;
    position: relative;
    padding: 60px 0;
}

.text-danger {
    display: inline-block;
    margin-bottom: 10px;
}

.dashboard_task_sec .add_text {
    background: rgba(63, 39, 131, 0.1);
    border: 1px solid #3f2783;
    border-radius: 10px;
    height: 60px;
    font-size: 14px;
    padding: 0 30px;
    max-width: 100%;
}

.dashboard_task_sec .addtask_section .add-field .form-group {
    width: calc(100% - 40px);
    display: inline-block;
}

.pagination-bottom {
    border-top: 1px solid rgba(63, 39, 131, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 120px 40px 20px;
    position: fixed;
    bottom: 0;
    left: 223px;
    width: calc(100% - 223px);
    background: #f3f5f7;
    z-index: 2;
}

.sidebar-collapsed .pagination.pagination-bottom {
    width: calc(100% - 120px);
    left: 104px;
}

.custom_sort_dropdown .custom_dropdown .arrow_img {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
}

.custom_sort_dropdown .custom_dropdown.show .arrow_img {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
}

.custom_sort_dropdown .custom_dropdown .btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu,
.formTimelineSec .custom_dropdown .dropdown-menu {
    width: 170px;
    border: 2px solid #3f2783;
    border-radius: 20px;
    background: #fff;
    color: #3f2783;
    z-index: 2;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
    padding: 6px 20px;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item.active,
.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item:active,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item.active,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item:active {
    background-color: #e9ecef;
}

.view_all_note .custom_sort_dropdown .custom_dropdown .dropdown-menu {
    left: -96px !important;
}
.Custom_re_se_drop {
    min-width: 200px;
    max-width: 285px;
    display: inline-block;
}
.basic-single_org {
    text-align: left;
}
select .option {
    width: 200px;
}
.basic-single_org .select__control {
    border-color: rgba(63,39,131,.7);
    border-radius: 10px;
    min-height: 48px !important;
    border-width: 2px;
    color: #3f2783;
}
.basic-single_org .select__control--is-focused {
    border-color: rgba(63,39,131,.7);
    min-height: 48px !important;
    border-width: 2px;
    outline: none !important;
    box-shadow: none;
}
.basic-single_org .select__control:hover {
    border-color: rgba(63,39,131,.7);
    min-height: 48px !important;
    border-width: 2px;
}
.basic-single_org .select__indicator svg{
    fill: #3f2783;
    height: 26px;
    width: 26px;
}
.basic-single_org .select__indicator-separator{
    display: none;
}
.basic-single_org .select__menu{
    z-index: 2;
    color: #3f2783;
}
.basic-single_org .select__single-value{
    color: #3f2783;
}

.formTimelineSec button#dropdown-basic1 {
    position: absolute;
    right: -30px;
    top: -9px;
}

.formTimelineSec button#dropdown-basic1 {
    position: absolute;
    right: -20px;
    top: -10px;
}

.formTimelineSec .dropdown-toggle::after {
    display: none;
}

.formTimelineSec .dropdown-item .action_icons {
    height: 18px;
    width: 18px;
    margin-right: 20px;
}

.formTimelineSec .mp_items .dropdown-item a,
.formTimelineSec .mp_items .dropdown-item span {
    color: #3F2783;
    font-weight: 500;
    display: block;
    font-size: 14px;
}

.Updated_dt {
    font-size: 12px;
    margin-bottom: 0;
}

.action_small_dev .dropdown-item .action_icons {
    margin-right: 20px;
}

.switch-title {
    font-weight: 600;
    margin-bottom: 15px;
    color: #3f2783;
}

.custom_radio_switch input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.custom_radio_switch label {
    display: inline-block;
    width: 100px;
    background-color: rgba(63, 39, 131, 0.1);
    color: rgb(63 39 131 / 22%);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 14px 10px;
    border: 1px solid #3f2783;
    box-shadow: inset 0 1px 3px rgba(63, 39, 131, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
}

.custom_radio_switch input:checked+label {
    box-shadow: none;
    color: #3f2783;
    transition: color 200ms;
    background-color: #fff;
    border: 2px solid #3f2783;
}

.custom_radio_switch.switch_active input:not(:checked)+label {
    box-shadow: none;
    color: rgba(63, 39, 131, 0.2);
    transition: color 200ms;
    background-color: rgba(63, 39, 131, 0.1);
    border: 2px solid rgba(63, 39, 131, 0.2);
}

.custom_radio_switch label:hover {
    cursor: pointer;
}

.custom_radio_switch #switch_left+label {
    border-right-width: 1px;
}

.custom_radio_switch #switch_right+label {
    border-left-width: 1px;
}

.custom_radio_switch.switch_active #switch_left+label {
    border-right-width: 2px;
}

.custom_radio_switch.switch_active #switch_right+label {
    border-left-width: 2px;
}

.custom_radio_switch label:first-of-type {
    border-radius: 20px 0 0 20px;
}

.custom_radio_switch label:last-of-type {
    border-radius: 0 20px 20px 0;
}

.text_area.form-control {
    max-width: 100%;
    height: auto;
    padding: 10px;
    white-space: normal;
}

.form-select:focus {
    border-color: #3f2783 !important;
    outline: none;
    box-shadow: none;
    color: #3f2783;
}

.default_c {
    color: #3f2783;
}

.custom-tbl {
    border-collapse: collapse;
    width: 100%;
}

table.custom-tbl tr .col-action {
    min-width: 180px;
}

.action_large_dev ul {
    /* min-width: 180px; */
}

.page_header {
    padding: 20px 0;
}

.page_header .ph_title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.tableContainer {
    /* border-radius: 20px; */
    height: 420px;
    padding: 0 10px 10px 10px;
    overflow: auto;
}

.tableContainer table thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    background: #3f2783;
    z-index: 1;
}

.tableContainer .infinite-scroll-component {
    overflow: inherit !important;
}

.panel {
    background: #fff;
    border-radius: 20px;
    margin-bottom: 15px;
    min-height: 100px;
}

.panel-heading {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.panel-footer {
    border-top: 1px solid rgba(63, 39, 131, 0.1);
}

.pannel_pd {
    padding: 10px 25px;
}

.p_25 {
    padding: 25px;
}

.pt_0 {
    padding-top: 0;
}

.adv_search_sec h4 {
    margin-bottom: 25px;
}

.welcome-section {
    text-align: center;
    position: relative;
    padding: 20px 0 60px 0;
}

.welcome-section h4 {
    font-weight: 600;
    font-size: 24px;
    margin: 20px 0 10px 0;
}

.welcome-section p {
    font-size: 18px;
}

.w-108 {
    width: 108px !important;
}

.char_limit_txt {
    text-align: right;
    color: #3f2783;
}

.upload_details {
    color: #3f2783;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.upload_details .file_icon {
    margin-right: 15px;
}

.upload_details .file_name {
    display: inline-block;
}

.modal-content {
    box-shadow: none;
    border: 2px solid #3f2783;
    border-radius: 20px;
}

.modal-header {
    border-bottom-color: #f4f4f4;
}

.modal-title {
    font-weight: 600;
    font-size: 18px;
}

.terms__condi--content .trms_txt {
    margin-bottom: 10px;
}

.terms__condi--content .trms_txt_sub {
    margin-bottom: 10px;
    padding-left: 10px;
    display: block;
}

.terms__condi--content .trms_txt_sub_sub {
    padding-left: 10px;
    display: block;
}

.terms__condi--content .txt__sub_ttl {
    text-decoration: underline;
}

.terms__content_ppup_bdy {
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
}

.terms__content_ppup_bdy::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ccc;
}

.terms__content_ppup_bdy::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}

.terms__condi--content .d__block {
    display: block;
}

.dashboard_left_panel {
    width: 275px;
    min-height: 200px;
}

.dashboard_right_panel {
    width: calc(100% - 277px);
    min-height: 200px;
}

.patient_notes_popup .modal-title {
    width: 100%;
}

.scrollbar_vert {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbar_hori {
    overflow-y: hidden;
    overflow-x: auto;
}

.scrollbar_vert::-webkit-scrollbar-track {
    border-radius: 10px;
}

.scrollbar_vert::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
}

.scrollbar_vert::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}

.scrollbar_hori::-webkit-scrollbar-track {
    border-radius: 5px;
}

.scrollbar_hori::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
}

.scrollbar_hori::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}

.initial_value {
    color: #ff0000;
}

.recent_value {
    color: #006dff;
}

.carousel-item {
    min-height: 300px;
    margin-bottom: 20px;
}

.carousel-indicators [data-bs-target] {
    width: 10px;
    border-radius: 50%;
    height: 10px;
    background-color: #3f2783 !important;
}

.carousel-control-prev-icon {
    background-image: url(../images/arrow-blue.png);
    transform: rotate(90deg);
}

.carousel-control-next-icon {
    background-image: url(../images/arrow-blue.png);
    transform: rotate(270deg);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-size: inherit;
    filter: none !important;
}

.CircularProgressbar .CircularProgressbar-text {
    font-weight: 600;
}

.patient_demographic .detail__txt .form-group {
    display: inline-block;
}

.patient_demographic .form-group {
    max-width: 260px;
    width: 100%;
}

.patient_demographic .form-group .form-select {
    border-width: 1px;
    margin: 5px 0;
}

.patient_demographic.form_disabled .form-group .form-select {
    border-width: 0px;
    margin: 5px 0;
}

.patient_demographic.form_disabled input,
.patient_demographic.form_disabled select {
    padding-left: 10px;
    padding-top: 4px;
    border: 0 solid #efefef;
    background: white;
    height: auto;
    color: #000 !important;
    transition: all 0.2s ease-in-out;
}

.patient_demographic .country_c {
    width: 110px;
}

.patient_demographic.form_disabled .country_c {
    width: 54px;
}

.patient_demographic.form_disabled .mobile__num input,
.patient_demographic.form_disabled .country_c input {
    padding-top: 0;
    margin-top: 0;
}

.score_range_slider .range_labels li.active {
    background: #fff;
    border: 2px solid #3F2783;
    color: #3F2783;
}

.score_range_slider .range_labels li.active span {
    opacity: 1;
    visibility: visible;
}

.patient_assessment_form .input_tab_box {
    border: 2px solid #3F2783;
    border-radius: 20px;
    padding: 25px;
    margin-right: 0 !important;
}

.range_sl_title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}

.form-group p.rd_label_title {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}

.pt_step_rd label {
    font-weight: 400;
}

.pt_privaa_btn {
    text-decoration: underline;
}

.assm_back_btn span {
    display: inline-block;
    color: #3f2783;
    font-size: 24px;
    margin-left: 10px;
}

.button_box {
    margin-top: 20px;
    padding: 30px;
}

.doctor_roles_map_screen .button_box {
    padding-left: 0;
    padding-right: 0;
}

.button_box .next_btn {
    width: 170px;
    height: 56px;
    font-size: 24px;
    line-height: 50px;
    border-radius: 50px;
    padding-right: 17px;
}

.assm_back_btn span {
    display: inline-block;
    color: #3f2783;
    font-size: 24px;
}

span.assm_back_btn {
    display: inline-block;
    color: #3f2783;
    background: transparent;
    outline: none;
    text-decoration: none;
    border: 2px solid #3f2783;
    width: 170px;
    height: 56px;
    font-size: 24px;
    line-height: 50px;
    border-radius: 50px;
    text-align: center;
}


button.next_btn.btn_fill span {
    display: none;
}

span.assm_back_btn img {
    display: none;
}


.button_box .next_btn span {
    margin-left: 10px;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.button_box .next_btn span img:first-child {
    display: none;
}

.button_box .next_btn:hover span img:first-child {
    display: inline-block;
}

.button_box .next_btn:hover span img:last-child {
    display: none;
}

.patient_assessment_form .accordion-item {
    border-radius: 20px;
}

.patient_assessment_form .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 24px;
    font-weight: 600;
}

.patient_assessment_form .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.patient_assessment_form .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #aab3bf00;
    box-shadow: none;
}

.patient_assessment_form .accordion-button:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.patient_assessment_form .accordion_tabpanel .accordion-button::after {
    background-image: url("../images/plus.png");
}

.patient_assessment_form .accordion_tabpanel .accordion-button:not(.collapsed)::after {
    background-image: url("../images/minus.png");
    height: 2px;
}

.patient_assessment_form .custom_radio_switch label {
    width: 160px;
}

.patient_assessment_form .accordion_tabpanel .switch-title {
    color: #000;
}

.settings_ppup_main .modal-content {
    min-height: 700px;
}

.settings_ppup_main .modal-header {
    border-color: rgba(63, 39, 131, 0.1);
    padding: 20px;
}

.settings_ppup_main .settings_ppup_bdy {
    padding: 0;
}

.settings_ppup_main .modal-header .btn-close {
    background: transparent url(../images/close-popup.svg) center/1em auto no-repeat;
}

.settings_main .nav-pills .nav-link.active,
.settings_main .nav-pills .nav-link:hover {
    color: #3f2783;
    font-weight: 600;
    background-color: rgba(63, 39, 131, 0.1);
}

.settings_main .nav-pills .nav-link img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    margin-top: -1px;
}

.settings_main .nav-pills .nav-link .blue_icon {
    display: none;
}

.settings_main .nav-pills .nav-link.active .grey_icon,
.settings_main .nav-pills .nav-link:hover .grey_icon {
    display: none;
}

.settings_main .nav-pills .nav-link.active .blue_icon,
.settings_main .nav-pills .nav-link:hover .blue_icon {
    display: inline-block;
}

.settings_main .language_drp_dwn .form-select {
    border-radius: 10px;
    min-height: 50px;
}

.form-group select {
    background: url(../images/download1.png) no-repeat right;
    background-size: 26px;
    cursor: pointer;
}

.react-datepicker__input-container .form-control {
    background: url(../images/calendar.png) no-repeat right;
    background-size: 18px;
    background-position: right 0.75rem center;
    cursor: pointer;
}

#orglisttableDiv {
    border: 1px solid #3f2783;
    border-radius: 20px;
    margin-bottom: 50px;
}

#orglisttableDiv table.custom-tbl thead tr th {
    padding: 16px 10px;
}

#orglisttableDiv table.custom-tbl tbody tr td {
    padding: 14px 10px;
}

#orglisttableDiv table.custom-tbl thead tr .col-orgid {
    width: 30%;
}

#orglisttableDiv table.custom-tbl thead tr .col-orgname {
    width: 50%;
}

#orglisttableDiv table.custom-tbl thead tr .col-status {
    width: 20%;
}

.initial.progress {
    height: 8px;
    ;
}

.recent.progress {
    height: 8px;
    ;
}

.initial .progress-bar {
    background-color: #ff0000;
    height: 8px;
    border-radius: 5px;
    width: 100%;
}

.recent .progress-bar {
    background-color: #006dff;
    height: 8px;
    border-radius: 5px;
    width: 100%;
}

.opn_subtitle {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    padding-bottom: 15px;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
}

.opn_subtitle_left {
    padding-bottom: 15px;
    margin: 0 !important;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
}

.br_btm {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.fullw-separator {
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    margin: 0 -25px;
    width: 103%;
    margin-top: 15px;
    padding-top: 15px;
}

.op_typelist_col {
    margin: 20px 20px 0px 0;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 215px;
    height: 225px;
    padding: 10px;
    position: relative;
}

.op_typelist_col .op_type_radio {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    text-align: right;
}

.op_typelist_col .op_type_radio input {
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
}

.op_typelist_col .op_typelist-content {
    margin-top: 25px;
}

.ideberg_sec .op_typelist_col .op_typelist-content {
    margin-top: 5px;
}

.op_typelist_col [type="radio"]+label {
    padding-right: 3px;
    margin-top: 10px;
}

.op_typelist_col .op_typelist-content p {
    display: block;
    font-weight: 600;
    color: #3f2783;
    margin-top: 15px;
}

.inst_reset_btn {
    position: absolute;
    right: 20px;
    top: 24px;
    font-weight: 600;
    cursor: pointer;
}

.position_rl {
    position: relative;
}

.artho_walch_sec .op_list_Img {
    min-height: 100px;
    margin-bottom: 20px;
}

.artho_gupta_seebauer .op_typelist-content {
    margin-top: 0;
}

.artho_gupta_seebauer .op_typelist_col {
    height: 236px;
}

.artho_gupta_seebauer .mb_10 {
    margin-bottom: 10px !important;
}

.create_newnote_pop .modal-dialog {
    max-width: 1177px;
    margin-top: 100px;
}

.create_newnote_pop .modal-header {
    border-bottom-color: rgba(63, 39, 131, 0.1);
    padding: 20px 30px;
}

.create_newnote_pop .modal-title {
    font-size: 18px;
    color: #000;
    line-height: 24px;
    text-align: center;
}

.create_newnote_pop .form-group {
    margin-bottom: 20px;
}

.create_newnote_pop .create_newnote_pop_bdy.modal-body {
    padding: 20px 30px 40px 30px;
}

.saveedNote_rightPop .search-box,
.view_all_note .search-box,
.nav_inner_search .search-box {
    width: 287px;
    position: relative;
    border: 1px solid rgba(63, 39, 131, 0.7);
    margin: inherit;
    border-radius: 20px;
    height: 40px;
    text-align: center;
    margin-top: 16px;
}

.nav_inner_search .search-box {
    margin-top: 0;
}

.search-box .input-group {
    width: 100%;
}

.search-box .input-group .form-control {
    border: 0;
    height: 36px;
    padding: 0 5px;
    color: #3f2783;
    font-size: 18px;
    margin-top: 0px;
    text-align: center;
}

.search-box .input-group .input-group-addon {
    border: 0;
    background: transparent;
    padding: 0;
    width: 50px;
    text-align: center;
    height: 36px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.search-box .input-group .input-group-addon.first img:last-child {
    display: none;
}

.search-box .input-group.search_active .input-group-addon.first img:last-child {
    display: block;
}

.search-box .input-group.search_active .input-group-addon.first img:first-child {
    display: none;
}

.saveedNote_rightPop .search-box .input-group .form-control,
.view_all_note .search-box .input-group .form-control,
.nav_inner_search .search-box .input-group .form-control {
    font-size: 14px;
}

.form-control:focus {
    color: #3f2783;
    outline: 0;
    box-shadow: none;
}

.search-box .search_close_icon {
    display: none;
}

.search-box .search_active .search_close_icon {
    display: block;
}

.chec_lb_before {
    color: #3f2783;
    font-size: 16px;
    font-weight: 600;

}

.chec_lb_before label {
    margin-bottom: 0;
}

.chec_lb_before input {
    margin: 0 10px;
    width: 16px;
    height: 16px;
}

.time_interval_filed label {
    margin-bottom: 42px;
}

.panel h4 {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.satisfaction_screen .satis_drp label {
    font-weight: 400;
}

.satisfaction_screen .satis_drp .form-select {
    max-width: 300px;
}

.complete_screen .thanks_txt {
    font-size: 18px;
    margin-bottom: 40px;
}

.complete_screen .cmp_submit_btn,
.result_screen .result_submit_btn {
    width: 220px;
    height: 62px;
    font-size: 20px;
    border-radius: 30px;
    margin-bottom: 20px;
}

.complete_screen .answer_txt {
    font-size: 12px;
    display: block;
}

.result_screen .answer_txt {
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
}

.forms_map_screen .button_box {
    padding: 30px 0;
}

.forms_map_screen .ph_title {
    margin-bottom: 60px;
}

.location_ select.form-control.form-select {
    border: 2px solid rgba(63, 39, 131, 0.7);
    min-width: 140px;
    font-size: 14px;
}

.content__main_reg {
    margin-top: 22vh;
}

.assm_back_btn img {
    margin-bottom: 7px;
}

.model_width {
    max-width: 80% !important;
}

.box_d {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px 15px 13px 15px;
    width: 100%;
    /* height: 260px; */
    margin: 10px;
    min-height: 250px;
}


.btn_view {
    border: 2px solid #3f2783;
    color: #3f2783;
    border-radius: 50px;
    font-size: 14px;
    padding: 0 22px;
    min-width: 125px;
    font-weight: 500;
    height: 40px;
    line-height: 37px;
    cursor: pointer;
    background: transparent;
}

.f_25 {
    font-size: 25px;
}

.f_18 {
    font-size: 18px;
}

.MyVisits_visits_right_btn__2nwZe {
    width: 50px;
    text-align: right;
}

.table_color {
    border-color: 1px #ccc;
    text-align: center;
    border-color: rgba(63, 39, 131, 0.1) !important;
}

.no_data {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: #8f8e8e;
    padding-bottom: 100px;
    padding-top: 100px;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 0px solid rgba(173, 173, 173, 0.2) !important;
    margin-bottom: 60px;
}

.username-info span {
    font-size: 12px;
    opacity: 1;
    overflow-wrap: break-word;
}

.pathology_page .img_box {
    min-height: 90px;
}

.pathology_page .dt_individual_top_sec .whitebox h4 {
    padding-top: 27px;
    padding-bottom: 15px;
}

.btn-outline-common {
    border: 2px solid #3f2783;
    color: #3f2783;
    border-radius: 50px;
    font-size: 18px;
    padding: 0 22px;
    height: 40px;
    line-height: 40px;
    background: transparent;
}

.pathology_page {
    padding-top: 100px;
}

.box-content {
    max-height: 308px;
    padding: 40px 20px;
    height: 301px;
}

.box_d h4 {
    word-break: break-all;
}

.box_d strong {
    font-size: 35px !important;
}

.box_d h4 {
    font-size: 17px !important;
}

.box_d label strong {
    font-size: 20px !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 9px;
    height: 40px;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
    color: rgb(63, 39, 131);
    padding: 0px 15px;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
    z-index: 1;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
    font-size: 18px;
    margin: auto;
}

/* .css-1qqsdnr-MuiAutocomplete-root {
    max-width: 655px !important;
    width: 644px !important;
} */

.bPNNyh>svg {
    fill: #bdb8b8 !important;
}

.hCpHso .selected {
    background-color: #bbb6b642 !important;
}

.fBLtRQ {
    min-height: 33px !important;
}

.fBLtRQ>input {
    font-size: 14px !important;
}

.fBLtRQ>input ::placeholder {
    color: #3f2783 !important;
}

/* span.white_shade {
    width: 12px;
    height: 18px;
    background-color: #ffffff;
    position: absolute;
    right: 12px;
    top: 11px;
    z-index: 3;
    border-radius: 57px;
} */
.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 2px !important;
    background-color: #ffff !important;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
    border: 2px solid #3f2783b3 !important;
    border-radius: 10px;
    height: auto;
}

.MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root {
    border: 2px solid #3f2783b3!important;
    border-radius: 10px;
    height: auto;
}

.MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
}

.panel-body.p_25 h2 {
    font-size: 24px;
}

.text-right {
    text-align: right;
}

.w_350 {
    width: 350px;
}

.w_160 {
    width: 160px;
}

.w_180 {
    width: 180px;
}

.w_210 {
    width: 210px;
}

.mr_auto {
    margin: 0 auto;
}

.pt_50 {
    padding-top: 50px;
}

.d__flex {
    display: flex;
}

.ms_3 {
    margin-left: 1rem !important;
}

.me_2 {
    margin-right: 0.5rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.l_height_38 {
    line-height: 38px;
}

.f_14 {
    font-size: 14px;
}

.d_flex {
    display: flex;
}

.tag_hea_right {
    width: calc(100% - 160px);
    display: flex;
    align-items: baseline;
}

.tag_hea_right .form-group {
    width: 170px;
    margin-left: 20px;
}
.create_org_screen, .edit_org_screen {
    margin-bottom: 100px;
}
.create_org_screen .multi_select_main .searchWrapper, .create_org_screen .multi_select_main .dropdown-container,
.edit_org_screen .multi_select_main .searchWrapper, .edit_org_screen .multi_select_main .dropdown-container {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
}

@media (min-width: 768px) {
    .modal-dialog.modal-md {
        max-width: 600px;
        margin: 30px auto;
    }
}

@media only screen and (min-width: 1401px) {
    .dashboard_left_panel {
        width: 415px;
    }

    .dashboard_right_panel {
        width: calc(100% - 417px);
    }
}

@media only screen and (min-width: 1700px) {
    .dashboard_right_panel {
        width: calc(1347px - 417px);
    }
}

@media only screen and (max-width: 1199px) {

    .user_list .tableContainer {
        overflow-x: auto;
        width: auto;
    }
    .approve_list_tbl .btn_green{
        margin-bottom: 6px;
    }
    table.custom-tbl tr .col-action {
        min-width: auto;
        padding-right: 15px;
        text-align: right;
    }
    table .action_small_dev .dropdown-item .action_icons {
        width: 22px;
        height: 22px;
    }

    #sidebar_main.pro-sidebar {
        width: 100%;
        height: 100px;
        margin-top: 0;
        position: fixed;
        bottom: 0;
    }

    .content__main {
        margin-left: 0;
    }

    .pro-sidebar .pro-menu ul {
        display: flex;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
        flex-direction: column;
        padding: 8px;
    }

    .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
        overflow-y: hidden;
        overflow-x: auto;

    }

    #sidebar_main.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
        margin-right: 0;
    }

    .pro-sidebar-header {
        display: none;
    }

    #sidebar_main.pro-sidebar>.pro-sidebar-inner {
        background: #3f2783;
        border-radius: 0 0 0 0;
    }

    #sidebar_main.pro-sidebar.collapsed {
        width: 100%;
        min-width: 100%;
    }

    .rp_100 {
        width: 100%;
    }

    .settings_ppup_main .modal-dialog.modal-lg {
        max-width: 96%;
    }
}

@media only screen and (min-width: 1200px) {
    table.custom-tbl tr:hover .action_large_dev {
        display: block;
    }

    .settings_ppup_main .modal-dialog.modal-lg {
        max-width: 1177px;
        margin-top: 106px;
        margin-bottom: 106px;
    }

    .patient_assessment_form.fixed_vertical_menu .navbar {
        position: fixed;
        top: 30px;
    }
}

@media only screen and (min-width: 1250px) {
    .content_fx_w {
        margin: 0 auto;
        width: 1236px;
    }
}

@media only screen and (min-width: 992px) {
    .nav_inner_search .search-box {
        width: 400px;
    }

    .nav_inner_search .search-box .input-group {
        justify-content: space-between
    }

}

.dropdown_main_1 {
    width: 100%;
    display: flex;
    position: relative;
}

.dropdown_main_1 input {
    padding: 0 0 0 14px !important;
    background-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    color: #3f2783;
    font-family: poppins;

}

.dropdown_main_1 input:active {
    border: none !important;
}

.dropdown_main_1 input:hover {
    border: none !important;
    background-color: #fff !important;
    outline: none !important;
}

.dropdown_main_1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    outline: none !important;

}

.dropdown_main_1 .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
    border: 2px solid rgba(63, 39, 131, .7) !important;
    border-radius: 10px;
}

.dropdown_main_1 .MuiAutocomplete-root .Mui-focused .MuiFormControl-root .MuiInputBase-root .Mui-focused {
    border: 2px solid rgba(63, 39, 131, .7) !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root.Mui-focused {
    border: 2px solid #3f2783 !important;
    outline: none !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;


}

.dropdown_main_1 .css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 32px !important;
    background-color: #fff !important;
    height: 30px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #FFF !important;
}

table.custom-tbl {
    border-collapse: collapse;
    width: 100%;
}

table.custom-tbl thead tr th {
    font-weight: 600;
    font-size: 14px;
    color: #3F2783;
    padding: 10px 0;
    width: 20%;
}

table.custom-tbl>thead>tr>th {
    border-bottom: 1px !important;
}

table.custom-tbl tr th {
    border-color: rgba(63, 39, 131, 0.1) !important;
}

table.custom-tbl td {
    line-height: 28px;
    font-size: 14px;
    color: #3f2783;
}

table.custom-tbl tbody tr td {
    font-size: 14px;
    color: #3f2783;
    padding: 14px 2px;
    border-top: 1px solid rgba(63, 39, 131, 0.1);
}

table.custom-tbl thead tr th.col-img {
    width: 80px;
    padding: 14px 5px;
}

table.custom-tbl thead tr th.col-img,
table.custom-tbl tbody tr td.col-img {
    padding: 14px 8px;
    padding-right: 20px;
    width: 5% !important;
}

.pagination {
    padding: 0.5rem;
}

.badg_active {
    /* background: #6fcf97; */
    background-color: #c6f6d5;
    color: #22543d;
}
button.badge.bdg_btn.badg_active:hover {
    border: 1px solid #22543d;
}

.badg_inactive {
    /* color: #3f2783;
    border: 1px solid #3f2783; */
    color: #7b341e;
    background-color: #feebc8;
}
button.badge.bdg_btn.badg_inactive:hover {
    border: 1px solid #7b341e;
}
.badg_pending {
    /* color: #3f2783;
    border: 1px solid #3f2783; */
    color: #212529;
    background-color: #ffc107;
}
button.badge.bdg_btn.badg_pending:hover {
    border: 1px solid #212529;
}

.bdg_btn {
    min-width: 80px;
    min-height: 30px;
    line-height: 30px;
    border: none;
    font-weight: 500;
    font-size: 13px;
    border-radius: 6px;
    padding: 0;
}


.error_tag {
    position: relative;
    bottom: 14px;
    margin-left: 7px;
}

.progressing-bar {
    text-align: justify;
    margin-top: -6px;
}

.t_responsive {
    white-space: nowrap !important;
    margin-bottom: 1.5rem !important;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.t_responsive table{ 
    width: 100%;
}
.t_responsive table {
    max-width: 960px;
    margin: 10px 0;
    width: 100%;
}
  

.card-border {
    border: 2px solid #3f2783;
    margin: 5px;
    height: auto;
    border-radius: 5px;
}

.card-border:hover {
    box-shadow: 1px 4px 9px 4px #3f278340;
}

.add-btn {
    position: relative;
    left: -37px;
}

.patient-map-name {
    height: 183px;
    background-color: #3f27837a;
    color: #fff;
    font-weight: 600;
    align-items: center;
    display: grid;
}

.patient-map-app {
    align-items: center;
    display: grid;
    text-align: center;
    height: 183px;
}


.card-border{
    border: 2px solid #3f2783;
    margin: 5px;
    height: auto;
    border-radius: 5px;
 }
 .card-border:hover {
     box-shadow: 1px 4px 9px 4px #3f278340;
 }
 .add-btn{
     position: relative;
     left: -37px;
 }
 .patient-map-name{
     height: 183px;
     background-color: #3f27837a;
     color: #fff;
     font-weight: 600;
     align-items: center;
     display: grid;
 }
 .patient-map-app{
     align-items: center;
     display: grid;
     text-align: center;
     height: 183px;
 }
 
 
 
 .card-borderss{
     border: 2px solid rgb(6, 6, 6); /* Replace "red" with your desired border color */
 
     display: flex; /* Add flex display */
     justify-content: center; /* Center the content horizontally */
     align-items: center; /* Center the content vertically */
     /* You can also add more styles to customize the appearance of the cards */
   }
   .patient_imgS:hover {
     transform: scale(1.1);
  
   }
   .patient_imgSS:hover {
     border: 2px solid #D9D4E6;
     background-color: #cccccc36;
   }
   .patient_imgS {
     transition: all 0.2s ease-in;
   }

   .search-org{
    height: 48px;
    border-radius: 10px;
   }

   


   .scroller_pt_list{
    overflow: auto;
    height: 60vh;
}

.custom-checkbox {
    position: relative;
  }
  
  .custom-checkbox input[type="checkbox"] {
    width: 20px; /* Set the width to make it larger */
    height: 24px; /* Set the height to make it larger */
    border: 2px solid rgba(63, 39, 131, 0.7);
    /* Add other styles as needed */
  }

 
  .groups__list table.custom-tbl4 thead tr th {
    width: 5% !important;
}
.org_email{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 280px;
}

.table5 thead tr th {
    width: 12% !important;
}
















.multi_select_drp_select_plat{
    max-width: 560px !important;
}




.multi_select_drp_select_plat .css-yk16xz-control,
.multi_select_drp_select_plat .css-1pahdxg-control,
.multi_select_drp_select_plat .css-1s2u09g-control,
.multi_select_drp_select_plat .css-13cymwt-control,
.multi_select_drp_select_plat .css-t3ipsp-control,
.time_picker .react-time-picker__wrapper {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 9px !important;
    color: #3f2783 !important;
    padding: 0 !important;
    min-height: 40px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
    box-shadow: none !important;
}
.multi_select_drp_select_plat .css-13cymwt-control{
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 9px !important;
    color: #3f2783 !important;
    padding: 0 !important;
    min-height: 40px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
    box-shadow: none !important;
}
.multi_select_drp_select_plat .css-t3ipsp-control{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}

.multi_select_drp_select_plat .css-t3ipsp-control:hover{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}

















.multi_select_drp_select_plat_users{
    /* max-width:522px !important; */
    max-width: 80% !important;

}




.multi_select_drp_select_plat_users .css-yk16xz-control,
.multi_select_drp_select_plat_users .css-1pahdxg-control,
.multi_select_drp_select_plat_users .css-1s2u09g-control,
.multi_select_drp_select_plat_users .css-13cymwt-control,
.multi_select_drp_select_plat_users .css-t3ipsp-control,
.time_picker .react-time-picker__wrapper {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 9px !important;
    color: #3f2783 !important;
    padding: 0 !important;
    min-height: 40px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
    box-shadow: none !important;
}
.multi_select_drp_select_plat_users .css-13cymwt-control{
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 9px !important;
    color: #3f2783 !important;
    padding: 0 !important;
    min-height: 40px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
    box-shadow: none !important;
}
.multi_select_drp_select_plat_users .css-t3ipsp-control{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}

.multi_select_drp_select_plat_users .css-t3ipsp-control:hover{
    border-color:  2px solid rgba(63, 39, 131, 0.7) !important;
}

.org_user_hcp_tablemulti_select_drp_select.css-b62m3t-container {
    width: 191px;
}


.emailError{
    font-weight: 600;
    color: #3f2783;
    padding: 6px 16px;
}

div#pdf-controls {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: -webkit-sticky;
    position: absolute !important;
    top: -56px !important;
    left: 0 !important;
    right: 500px !important;
    z-index: 1;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 8px;
    background-color: #ffffff00 !important;
    box-shadow: none !important;
}

.table6 thead tr th{
    width:22% !important
  }
  .img_hover_view {
    display: none;
    position: absolute;
    top: 14px;
  }
  .img_white {
    position: relative;
    display: inline-block;
  }
  .img_white:hover .img_hover_view {
    display: block;
  }
  
  .img_white:hover .img_hover {
    display: block;
  }
  
  .img_white:hover .img_hover_doc {
    display: block;
  }
  
  .img_white:hover .img_hover2 {
    display: block;
  }
  .img_white:hover .img_hover3 {
    display: block;
  }  

  .dropdown_main_12 .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root{
    border-radius: 25px !important;
  }


.tags-channels-autocomeple {
    /* width: 219px !important; */
    max-width: 655px !important;
}
.css-xxees4 .MuiOutlinedInput-root{
    padding: 0px !important;
}
input#free-solo-demo {
    margin-left: 10px;
    font-size: 14px;
    line-height: 1;
    border-radius: 40px;
}
.dropdown_main_1.dropdown_main_12 input {
    height: 40px;
}

.add_btn1 {
    position: absolute;
    color: #3f2783;
    font-weight: 500;
    cursor: pointer;
    font-size: 10px;
    border: 2px solid;
    border-radius: 34px;
    height: 28px;
    width: 28px;
    margin-top: -8px;
}

span.add_btn1 img {
    width: 16px;
    margin-left: 5px;
    padding: 0px;
    margin-top: 4px;
}