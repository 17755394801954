#sidebar_main {
  width: 222px;
  min-width: 210px;
}

#sidebar_main.pro-sidebar {
  height: calc(100% - 72px);
  margin-top: 72px;
  position: fixed;
}

#sidebar_main.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#sidebar_main.pro-sidebar {
  color: #fff;
  border-radius: 0 20px 20px 0;
}

#sidebar_main.pro-sidebar>.pro-sidebar-inner {
  background: #3f2783;
  border-radius: 0 20px 20px 0;
}

#sidebar_main.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: initial;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}

#header .logo {
  padding: 20px;
}

.closemenu {
  padding-left: 30px;
}

.closemenu img {
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.pro-sidebar.collapsed .closemenu img {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.pro-menu-item.active div.pro-inner-item,
.pro-menu-item:hover div.pro-inner-item {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon img,
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  opacity: 0.7;
}

.pro-sidebar .pro-menu .pro-menu-item.active>.pro-inner-item>.pro-icon-wrapper .pro-icon img,
.pro-sidebar .pro-menu .pro-menu-item.active>.pro-inner-item>.pro-item-content,
.pro-sidebar .pro-menu .pro-menu-item:hover>.pro-inner-item>.pro-icon-wrapper .pro-icon img,
.pro-sidebar .pro-menu .pro-menu-item:hover>.pro-inner-item>.pro-item-content {
  opacity: 1;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  color: #fff;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 14px;
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 10px;
  color: #fff;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 8px 35px 8px 12px;
}
.pro-sidebar-content {
  height: 100%;
  overflow-y: auto;
}
.pro-sidebar-footer .username-info {
    padding: 0 30px;
    padding-top: 10px;
    padding-right: 5px;
}
.pro-sidebar-footer .username-info h5 {
  font-size: 17px ;
  color: #fff;
  margin: 0;
  font-weight: normal;
  margin-bottom: 0;
  word-wrap: break-word;
}
.pro-sidebar-footer .username-info span {
  opacity: 1;
  font-size: 11px;
  overflow-wrap: break-word;
}
.pro-sidebar-footer .app_ver_txt_menu_responsive {
  padding: 6px 15px 0 30px;
  font-size: 10px;
  margin-bottom: 0;
}
.left_sidebar_body::-webkit-scrollbar-track {
  border-radius: 10px;
}
.left_sidebar_body::-webkit-scrollbar {
  width: 8px;
  /* background-color: #fff; */
}
/* .left_sidebar_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(63, 39, 131, 0.2);
} */
.pro-sidebar-footer .user_letter {
  display: none;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background: #5e60ce;
  border-radius: 50%;
  line-height: 36px;
  border: 2px solid #3F2783;
  text-align: center;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-top: 10px;
}
.pro-sidebar.collapsed .user_letter{
  display: block;
}
span.user_letter img {
  width: 44px;
  height: 44px;
  margin: -3px;
  background: #5e60ce;
  border-radius: 50%;
  border: 2px solid #3F2783;
}
.pro-sidebar.collapsed .username-info{
  display: none;
}
.pro-sidebar.collapsed .app_ver_txt_menu_responsive {
  font-size: 11px;
  margin-top: 15px;
  padding: 0;
  text-align: center;
}
@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
@media only screen and (max-width: 1199px) {
  .username-info {
    display: none;
  }
  .pro-sidebar-footer .app_ver_txt_menu_responsive {
    display: none;
  }
  .pro-sidebar-footer {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .left_sidebar_body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}