.org__list .new__org_btn {  
    min-height: 40px;
    display: inline-block;
    text-align: center;
    min-width: 140px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}
.org__list .new__org_btn img{  
    margin-right: 10px;
}
.approve_list_tbl table.custom-tbl tr .col-action {
    min-width: 220px;
}
.org__list .invite__org_btn {
    width: 180px;
    height: 44px;
    border-radius: 30px;
}
.edit_org_screen .edit_btn_back, .edit_hcp_screen .edit_btn_back,  .edit_patient_screen .edit_btn_back{
    min-height: 56px;
    line-height: 56px;
    width: 170px;
    display: inline-block;
    text-align: center;
    min-width: 140px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
}
.edit_org_screen .next_btn, .create_hcp_screen .next_btn, .edit_hcp_screen .next_btn, .edit_patient_screen .next_btn{
    font-size: 18px;
}
.edit_org_screen label, .create_org_screen label {
  font-size: 16px;
  padding-bottom: 8px;
}

.org__list .App_logo {
    pointer-events: none;
    margin-top: 20px;
}
.org__list .db_welcome_section h4 {
  font-weight: 600;
  font-size: 24px;
  margin: 20px 0 10px 0;
  color: #000000;

}
.org__list .db_welcome_section p {
  font-size: 18px;
}
.org__list .card_individual_sec{
  padding-top: 60px;
}
.org__list .card_individual_sec .whitebox{
  max-height: 242px;
  padding: 40px 20px;
}
.org__list .card_individual_sec .whitebox h4 {
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
  color: #000000;

}
.org__list .card_individual_sec .whitebox p {
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 17.92px;
}
.org__list .card_individual_sec button.btn_outline {
  font-size: 14px;
  min-width: 126px;
  height: 38px;
  line-height: 35px;
}
.org__list .add_task_hr {
  border: 1px solid rgba(63, 39, 131, 0.2);
  border-radius: 10px;
  min-height: 60px;
  margin-bottom: 20px;
}
.org__list .add_task_hr span {
  display: block;
  padding: 16px 14px;
  font-size: 14px;
}
.org__list .add_task_hr .add_tsk_plus {
  width: 24px;
  margin: 0px 10px;
  margin-left: 5px;
}
.org__list .savetask img {
  cursor: pointer;
}
.approve_org__list .col-user-ids {
  display: flex;
  flex-wrap: wrap;
}
.approve_org__list table.custom-tbl thead tr th {
  width: 25%;
}
.org__list table .col-status{
  width: 8%;
}

@media only screen and (max-width: 992px) {
  .org__list .card_individual_sec .whitebox{
    max-height: 292px;
  }
}

@media (prefers-reduced-motion: no-preference) {
    .org__list .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  